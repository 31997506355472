<template>
  <div>
    <b-row>
      <b-col>
        <b-card>
          <apexchart
            height="250"
            :options="chartOptions.alertsOverTimeBySeverityChartOptions"
            :series="alertsBySeveritySeriesSorted"
          />
        </b-card>
      </b-col>
      <b-col />
    </b-row>
    <b-card>
      <AlertTable
        :alerts="sortedAlerts"
        :timezone="asup.timezone"
      />
    </b-card>
    <b-card title="Log">
      <div class="log-message">
        {{ asup.details.logMessage }}
      </div>
    </b-card>

  </div>
</template>

<script>

import {
  BRow, BCol, BCard,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import chartColorVars from '@/assets/scss/chart.scss'
import AlertTable from './components/AlertTable.vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    apexchart: VueApexCharts,
    AlertTable,
  },
  props: {
    asup: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      timeRange: {
        range: 'last3Days',
      },
      chartOptions: {
        alertsOverTimeBySeverityChartOptions: {
          chart: {
            id: 'alertsOverTimeBySeverityChart',
            type: 'bar',
            stacked: 'true',
            height: 350,
            zoom: {
              autoScaleYaxis: true,
            },
          },
          // eslint-disable-next-line no-unused-vars
          colors: [function ({ value, seriesIndex, w }) {
            switch (w.config.series[seriesIndex].name) {
              case 'CRITICAL': return chartColorVars.seriesCritical
              case 'ERROR': return chartColorVars.seriesError
              case 'WARNING': return chartColorVars.seriesWarning
              case 'INFO': return chartColorVars.seriesInfo
              case 'NOTICE': return chartColorVars.seriesNotice
              default: return chartColorVars.seriesDefault
            }
          }],
          dataLabels: {
            enabled: false,
          },
          stroke: {
            show: true,
            width: 2,
            colors: ['transparent'],
          },
          title: {
            text: 'Alerts over time by severity',
            align: 'left',
          },
          xaxis: {
            type: 'datetime',
          },
          yaxis: {
            title: {
              text: 'Count',
            },
            decimalsInFloat: false,
            forceNiceScale: true,
          },
          fill: {
            opacity: 1,
          },
          tooltip: {
            y: {
              formatter(val) {
                return `${val} alerts`
              },
            },
          },
          legend: {
            showForSingleSeries: true,
          },
        },
      },
    }
  },
  computed: {
    chartIds() {
      const chartIds = []
      // eslint-disable-next-line no-unused-vars
      Object.entries(this.chartOptions).forEach(([key, chartOption]) => {
        chartIds.push(chartOption.chart.id)
      })

      return chartIds
    },
    alerts() {
      const { alerts } = this.asup.details
      return alerts
    },
    sortedAlerts() {
      // use concat to clone array to prevent overwriting original array
      return this.alerts.concat().sort((a, b) => (a.postTime > b.postTime ? -1 : 1))
    },
    alertsBySeverity() {
      const alertsBySeverity = this.alerts.reduce((acc, alert) => {
        if (!acc[alert.severity]) {
          acc[alert.severity] = []
        }
        acc[alert.severity].push(alert)

        return acc
      }, {})

      return alertsBySeverity
    },
    alertsBySeveritySeries() {
      const series = []
      // for each severity
      Object.keys(this.alertsBySeverity).forEach(severity => {
        // group by date (day)
        const alertCountByDay = this.alertsBySeverity[severity].reduce((acc, alert) => {
          const postTimeDay = this.adjustByTimezone(this.$moment.utc(alert.postTime)).startOf('day').toISOString()
          if (!acc[postTimeDay]) {
            acc[postTimeDay] = 1
          } else {
            acc[postTimeDay] += 1
          }

          return acc
        }, {})

        const severityData = []
        Object.keys(alertCountByDay).forEach(day => {
          severityData.push([day, alertCountByDay[day]])
        })

        series.push({
          name: severity,
          data: severityData,
        })
      })

      return series
    },
    alertsBySeveritySeriesSorted() {
      const sortMap = ['CRITICAL', 'ERROR', 'WARNING', 'INFO', 'NOTICE']
      return this.alertsBySeveritySeries.concat().sort((a, b) => (sortMap.indexOf(a.name) < sortMap.indexOf(b.name) ? 1 : -1))
    },
  },
  methods: {
    adjustByTimezone(dateTime) {
      const offset = this.$moment.tz.zone(this.asup.timezone).utcOffset(dateTime)
      return dateTime.clone().subtract(offset, 'minutes')
    },
  },
}

</script>

<style scoped>
  .log-message {
    white-space: pre-line;
  }
</style>
